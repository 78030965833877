import React, { Component, Fragment } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import ErrorBoundary from '../common/Layout/ErrorBoundary'
import Header from '../common/Layout/Header'
import { history } from '../helpers/history'
import AuthenticatedRoutes from './Authentication/AuthenticatedRoutes'

import {
  fixUtilityBasket,
  removeHeaderShade,
  setHeaderShade,
  unfixUtilityBasket
} from '../redux/actions/ui.actions'
import i18n from '../../i18n'
import SvgIcons from '../common/SimpleComponents/SvgIcons'
import 'moment/locale/ka'

import HttpErrorModal from '../common/Modal/HttpErrorModal'

import LoadGlobalSettings from './LoadGlobalSettings'

import Loadable from 'react-loadable'
import { LbLoader } from '../common/SimpleComponents/LBLoader'
import { connect } from 'react-redux'
import Favicons from '../common/Layout/Favicons'
import I18Global from '../common/Layout/I18Global'
import Icon from '../common/SimpleComponents/Icon'

//import AccountsDetailContainer from './Account/AccountsDetailContainer'
import GlobalMessageDialog from '../common/Modal/GlobalMessageDialog'

import { setTranscationEndPoint } from '../api/transaction.api'
import hasUserPermission, {
  corporateUserPermissions
} from '../helpers/hasUserPermission'
import SvgIconsCorporate from '../common/SimpleComponents/SvgIconsCorporate'
import SvgIconsRetail from '../common/SimpleComponents/SvgIconsRetail'
import SpecRateCurrentTasksGlobal from '../corporateComponents/Transactions/CurrentTasks/SpecRateCurrentTasksGlobal'
import 'react-toastify/dist/ReactToastify.css'
import LoansDelayMessageDialog from '../common/Modal/LoansDelayMessageDialog'
import ToastNotificationGlobal from '../common/ToastNotificationGlobal'
import { ScrollToTop } from '../common/Layout/ScrollToTop'
import userManager from '../helpers/userManager'
import { store } from '../redux/state'
import { processSilentRenew } from 'redux-oidc'

const Loading = () => <LbLoader isLoading inCenter />

const AccountsDetailContainer = Loadable({
  loader: () => import('./Account/AccountsDetailContainer'),
  loading: Loading
})

const DashboardPage = Loadable({
  loader: () => import('./Dashboard/DashboardPage'),
  loading: Loading
})

const AccountsPage = Loadable({
  loader: () => import('./Account/AccountsPage'),
  loading: Loading
})

const TransferPage = Loadable({
  loader: () => import('./Transfer/TransferPage'),
  loading: Loading
})

const PaymentPage = Loadable({
  loader: () => import('./Payments/PaymentPage'),
  loading: Loading
})

// const AccountsDetailContainer = Loadable({
//   loader: () => import('./Account/AccountsDetailContainer'),
//   loading: Loading
// })

const CreditDetailsContainer = Loadable({
  loader: () => import('./Credit/CreditDetailsContainer'),
  loading: Loading
})

const DepositDetailsContainer = Loadable({
  loader: () => import('./Deposits/DepositDetailsContainer'),
  loading: Loading
})

const LoanDetailsContainer = Loadable({
  loader: () => import('./Loans/LoanDetailsContainer'),
  loading: Loading
})

const OfferContainer = Loadable({
  loader: () => import('./Offers/OfferContainer'),
  loading: Loading
})

const OfferActionRouter = Loadable({
  loader: () => import('./Offers/OfferActionRouter'),
  loading: Loading
})

const InvestmentDetailsContainer = Loadable({
  loader: () => import('./Investments/InvestmentDetailsContainer'),
  loading: Loading
})

const PayFullAmountLoanContainer = Loadable({
  loader: () => import('./Loans/PayFullAmount/PayFullAmountLoanContainer'),
  loading: Loading
})

const TemplateManagementContainer = Loadable({
  loader: () => import('./TemplateManagement/TemplateManagementContainer'),
  loading: Loading
})

const EditTemplateContainer = Loadable({
  loader: () => import('./TemplateManagement/EditTemplateContainer'),
  loading: Loading
})

const TransactionsPage = Loadable({
  loader: () => import('./Transactions/TransactionsPage'),
  loading: Loading
})

const FAQPage = Loadable({
  loader: () => import('./FAQ/FAQPage'),
  loading: Loading
})

const ExchangePage = Loadable({
  loader: () => import('./Exchange/ExchangePage'),
  loading: Loading
})

const SettingsPage = Loadable({
  loader: () => import('./Settings/SettingsPage'),
  loading: Loading
})

// const StandingOrders = Loadable({
//   loader: () => import('./StandingOrders/StandingOrders'),
//   loading: Loading
// });

const MoneyTransfers = Loadable({
  loader: () => import('./Remittances/RemittancePage'),
  loading: Loading
})

const MobileTransfers = Loadable({
  loader: () => import('../corporateComponents/MobileTransfers'),
  loading: Loading
})

// const MaintenancePage = Loadable({
//   loader: () => import('./Authentication/MaintenancePage'),
//   loading: Loading
// });

const TransferCardToCard = Loadable({
  loader: () => import('./TransferCardToCard/TransferCardToCard'),
  loading: Loading
})
const StandingOrders = Loadable({
  loader: () => import('../components/StandingOrders'),
  loading: Loading
})

const InactiveSignOut = Loadable({
  loader: () => import('./Authentication/InactiveSignOut'),
  loading: Loading
})

const GeneralErrorPage = Loadable({
  loader: () => import('./GeneralErrorPage'),
  loading: Loading
})

const NotificationsPage = Loadable({
  loader: () => import('./Notifications/NotificationsPage'),
  loading: Loading
})

const CorporateStyles = Loadable({
  loader: () => import('../common/Layout/CorporateStyles'),
  loading: Loading
})

const DepositChargeContainer = Loadable({
  loader: () => import('./Deposits/DepositContainer'),
  loading: Loading
})

const P2pPage = Loadable({
  loader: () => import('./P2P/P2pPage'),
  loading: Loading
})

const ReceivedUnauthorized = Loadable({
  loader: () => import('./TransferCardToCard/ReceivedUnauthorized'),
  loading: Loading
})

const ReceiveAuthorized = Loadable({
  loader: () => import('./TransferCardToCard/ReceiveAuthorized'),
  loading: Loading
})

const Notification = Loadable({
  loader: () => import('./Transfer/notifications/NotificationContainer'),
  loading: Loading
})

const PayrollTransferContainer = Loadable({
  loader: () =>
    import('../corporateComponents/PayrollTransfer/PayrollTransferContainer'),
  loading: Loading
})
const RequestForMoneyContainer = Loadable({
  loader: () =>
    import('../components/RequestForMoney/RequestForMoneyContainer'),
  loading: Loading
})
const MyFinancesContainer = Loadable({
  loader: () => import('./MyFinances/MyFinancesContainer'),
  loading: Loading
})

class App extends Component {
  notification_request_interval = null

  constructor(props) {
    super(props)

    this.state = {
      needScrollShade: false,
      scrollToTop: false,
      blockScrollAction: false
    }

    this.changeLanguage = this.changeLanguage.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.toggleLanguage = this.toggleLanguage.bind(this)
    this.windowTop = this.windowTop.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // this.notification_request_interval = setInterval(() => {
    //   this.props.dispatch(checkForNewMessage());
    // }, getConfigValue('notification_request_intreval'));

    window.setElastictransaction = setTranscationEndPoint
  }

  componentWillUnmount() {
    // clearInterval(this.notification_request_interval);
    window.removeEventListener('scroll', this.handleScroll)
  }

  changeLanguage(lang) {
    i18n.changeLanguage(lang)
  }

  toggleLanguage(lang) {
    let newLang = lang === 'en-US' ? 'ka' : 'en-US'
    i18n.changeLanguage(newLang)
  }

  windowTop() {
    window.scrollTo(0, 0)
  }

  handleScroll(event) {
    if (this.props.scrollEventHandlers) {
      for (const key of Object.keys(this.props.scrollEventHandlers)) {
        this.props.scrollEventHandlers[key](event)
      }
    }

    if (
      this.state.blockScrollAction &&
      document.documentElement.scrollTop === 0
    ) {
      this.setState({ blockScrollAction: false })
    }

    if (!this.state.blockScrollAction) {
      if (window.scrollY > 50 && !this.showHeaderShade) {
        this.showHeaderShade = true
        this.props.dispatch(setHeaderShade())
      } else if (window.scrollY <= 50 && this.showHeaderShade) {
        this.showHeaderShade = false
        this.props.dispatch(removeHeaderShade())
      }
    }

    if (history.location.pathname === '/payments/utility') {
      if (window.scrollY > 80 && !this.fixUtilityBasket) {
        this.fixUtilityBasket = true
        this.props.dispatch(fixUtilityBasket())
      } else if (window.scrollY <= 80 && this.fixUtilityBasket) {
        this.fixUtilityBasket = false
        this.props.dispatch(unfixUtilityBasket())
      }
    }

    //  adding scrollTop button to some pages
    if (window.scrollY > window.innerHeight / 2 && !this.state.scrollToTop) {
      this.setState({ scrollToTop: true })
    } else if (
      window.scrollY <= window.innerHeight / 2 &&
      this.state.scrollToTop
    ) {
      this.setState({ scrollToTop: false })
    }
  }

  renderThumbVertical() {
    return <div className="thumb-vertical" />
  }

  renderTrackVertical() {
    return <div className="track-vertical" />
  }

  scrollToTop = () => {
    this.setState({ blockScrollAction: true })

    const scrollTo = (element, to, duration) => {
      if (duration < 0) return
      let difference = to - element.scrollTop
      let perTick = (difference / duration) * 10

      setTimeout(() => {
        element.scrollTop = element.scrollTop + perTick
        scrollTo(element, to, duration - 10)
      }, 10)
    }

    // check if ie/edge
    const el = document.documentElement.scrollTop
      ? document.documentElement
      : document.body
    scrollTo(el, 0, 200)
  }

  render() {
    return (
      <Fragment>
        <SvgIcons />
        <Favicons />
        <Router history={history}>
          <ScrollToTop>
            <Switch>
              <Route path="/inactive_signout" component={InactiveSignOut} />
              <Route path="/general-error" component={GeneralErrorPage} />
              <Route
                path="/receiveunauthorized"
                component={ReceivedUnauthorized}
              />
              <Route
                render={() => (
                  <AuthenticatedRoutes>
                    <I18Global>
                      {(t, { i18n }) => (
                        <div className={`App ${i18n.language}`}>
                          <LoadGlobalSettings />
                          <ToastNotificationGlobal />

                          {/*{LbToast('warning', 'დავალება ელოდება დადასტურებას ', {*/}
                          {/*  autoClose: 30000*/}
                          {/*})}*/}
                          {/*{LbToast('success', 'დავალება ელოდება დადასტურებას ', {*/}
                          {/*  autoClose: 30000*/}
                          {/*})}*/}
                          {/*{LbToast('error', 'დავალება ელოდება დადასტურებას ', {*/}
                          {/*  autoClose: 30000*/}
                          {/*})}*/}

                          {hasUserPermission(true, false) ? (
                            <>
                              <CorporateStyles />
                              <SvgIconsCorporate />
                            </>
                          ) : (
                            <SvgIconsRetail />
                          )}
                          <Header
                            windowTop={this.windowTop}
                            needScrollShade={this.state.needScrollShade}
                            t={t}
                            i18n={i18n}
                          />
                          <h1>{process.env.REACT_APP_STAGE}</h1>
                          <div className="lb-container">
                            <ErrorBoundary history={history}>
                              <HttpErrorModal />
                              <GlobalMessageDialog />
                              {hasUserPermission(
                                corporateUserPermissions.TRANSFERS_OWN_ACCOUNT,
                                false
                              ) && <SpecRateCurrentTasksGlobal />}
                              {hasUserPermission(false, true) && (
                                <LoansDelayMessageDialog
                                  pathName={history.location.pathname}
                                />
                              )}
                              <Switch>
                                <Route
                                  exact
                                  path="/"
                                  component={DashboardPage}
                                />
                                <Route
                                  exact
                                  path="/products/:tab"
                                  component={AccountsPage}
                                />
                                <Route
                                  exact
                                  path="/products/:tab/notification"
                                  component={AccountsPage}
                                />
                                <Route
                                  exact
                                  path="/transfer/:tab/:notification"
                                  component={TransferPage}
                                />
                                <Route
                                  exact
                                  path="/transfer/:tab"
                                  component={TransferPage}
                                />
                                <Route
                                  exact
                                  path="/exchange/:tab"
                                  component={ExchangePage}
                                />
                                <Route
                                  exact
                                  path="/payments/:tab"
                                  component={PaymentPage}
                                />
                                <Route
                                  exact
                                  path="/payments/:tab/:meta"
                                  component={PaymentPage}
                                />
                                <Route
                                  exact
                                  path="/payments/:tab/:meta/:id"
                                  component={PaymentPage}
                                />
                                <Route
                                  exact
                                  path="/payments/:tab/:meta/:id"
                                  component={PaymentPage}
                                />
                                <Route
                                  exact
                                  path="/payments/:tab/:meta/:type/:id"
                                  component={PaymentPage}
                                />
                                <Route
                                  exact
                                  path="/accountsdetail/:accountId/:tab"
                                  component={AccountsDetailContainer}
                                />
                                <Route
                                  exact
                                  path="/creditdetails/:accountId/:tab"
                                  component={CreditDetailsContainer}
                                />
                                <Route
                                  exact
                                  path="/depositdetails/:depositId/charge/:tab"
                                  component={DepositChargeContainer}
                                />
                                <Route
                                  exact
                                  path="/depositdetails/:depositId/:tab"
                                  component={DepositDetailsContainer}
                                />

                                <Route
                                  exact
                                  path="/loandetails/:loanId/:tab"
                                  component={LoanDetailsContainer}
                                />
                                <Route
                                  exact
                                  path="/investmentdetails/:investmentId/:tab"
                                  component={InvestmentDetailsContainer}
                                />
                                <Route
                                  exact
                                  path="/loan/payfullamount/:loanId"
                                  component={PayFullAmountLoanContainer}
                                />
                                <Route
                                  exact
                                  path="/templates"
                                  component={TemplateManagementContainer}
                                />
                                <Route
                                  exact
                                  path="/manage-template/:type"
                                  component={EditTemplateContainer}
                                />
                                <Route
                                  exact
                                  path="/manage-template/:type/:id"
                                  component={EditTemplateContainer}
                                />
                                <Route
                                  path="/transactions/:tab"
                                  component={TransactionsPage}
                                />
                                <Route
                                  path="/payroll-transfer"
                                  component={PayrollTransferContainer}
                                />
                                <Route
                                  path="/request/money"
                                  component={RequestForMoneyContainer}
                                />
                                <Route
                                  exact
                                  path="/standing-orders/:tab/:id"
                                  component={StandingOrders}
                                />
                                <Route
                                  exact
                                  path="/standing-orders/:tab"
                                  component={StandingOrders}
                                />
                                <Route
                                  exact
                                  path="/standing-orders"
                                  component={StandingOrders}
                                />

                                <Route
                                  exact
                                  path="/trasnfer-cardtocard"
                                  component={TransferCardToCard}
                                />
                                <Route
                                  exact
                                  path="/receiveauthorized"
                                  component={ReceiveAuthorized}
                                />
                                <Route
                                  exact
                                  path="/remittance/:tab/:id"
                                  component={MoneyTransfers}
                                />
                                <Route
                                  exact
                                  path="/remittance/:tab"
                                  component={MoneyTransfers}
                                />
                                <Route
                                  exact
                                  path="/remittance"
                                  component={MoneyTransfers}
                                />
                                <Route
                                  exact
                                  path="/remittance/:tab/:notification"
                                  component={MoneyTransfers}
                                />
                                <Route
                                  exact
                                  path="/help/:id"
                                  component={FAQPage}
                                />
                                <Route exact path="/help" component={FAQPage} />
                                <Route
                                  exact
                                  path="/notifications"
                                  component={NotificationsPage}
                                />
                                <Route
                                  exact
                                  path="/corporate/mobile"
                                  component={MobileTransfers}
                                />
                                <Route
                                  exact
                                  path="/offers/:tab"
                                  component={OfferContainer}
                                />
                                <Route
                                  exact
                                  path="/offers/:tab/:item"
                                  component={OfferContainer}
                                />
                                <Route
                                  exact
                                  path="/offers/:tab/:item/:action"
                                  component={OfferActionRouter}
                                />
                                <Route
                                  path="/finances/:tab"
                                  component={MyFinancesContainer}
                                />
                                <Route path="/p2p/:tab" component={P2pPage} />
                                <Route
                                  path="/settings/:parameter"
                                  render={props => (
                                    <SettingsPage
                                      {...props}
                                      languageHandler={this.changeLanguage}
                                      toggleLanguage={this.toggleLanguage}
                                      currentLanguage={i18n.language}
                                    />
                                  )}
                                />
                                <Route
                                  exact
                                  path="/notification"
                                  render={props => (
                                    <div className="container mt-20">
                                      <Notification {...props} />
                                    </div>
                                  )}
                                />
                                <Route
                                  render={() => {
                                    history.push('/')
                                    return null
                                  }}
                                />
                              </Switch>
                            </ErrorBoundary>

                            {this.state.scrollToTop &&
                              (history.location.pathname.includes(
                                '/transactions/'
                              ) ||
                                (history.location.pathname.includes(
                                  '/accountsdetail/'
                                ) &&
                                  (history.location.pathname.includes(
                                    '/transactions'
                                  ) ||
                                    history.location.pathname.includes(
                                      '/statements'
                                    ) ||
                                    history.location.pathname.includes(
                                      '/blockedamounts'
                                    ))) ||
                                history.location.pathname.includes(
                                  '/BatchTransfers'
                                ) ||
                                history.location.pathname.includes(
                                  '/Detail'
                                )) && (
                                <div
                                  className="scroll-top"
                                  onClick={this.scrollToTop}
                                >
                                  <i className="icn icn-scrolltop">
                                    <Icon
                                      name="icn-scrolltop"
                                      view="0 0 12.519 7.042"
                                    />
                                  </i>
                                </div>
                              )}
                          </div>
                          {/* </Scrollbars> */}
                        </div>
                      )}
                    </I18Global>
                  </AuthenticatedRoutes>
                )}
              />
            </Switch>
          </ScrollToTop>
        </Router>
      </Fragment>
    )
  }
}

export default connect(state => {
  return {
    scrollEventHandlers: state.ui.scrollEventHandlers
  }
})(App)
