/* eslint-disable no-case-declarations */
import 'babel-polyfill'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import {
  CallbackComponent,
  createUserManager,
  OidcProvider,
  processSilentRenew
} from 'redux-oidc'
import App from './app/components/App'
import { setReduxStore, store } from './app/redux/state'
import {
  setUserManagerAuthorizationUrl,
  userManagerConfig
} from './app/helpers/userManager'
import { setServerConfig } from './app/constants/config'
import 'whatwg-fetch'
import './assets/scss/main.scss'
import { setBaseUrls } from './app/helpers/baseUrl'
import ReactGA from 'react-ga'
import { setCookie, updateObject } from './app/helpers/util'
import { logGeneralError } from './app/api/error-logger.api'
import MaintenancePage from './app/components/Authentication/MaintenancePage'
import TagManager from 'react-gtm-module'
import { setDiffFromServerTime } from './app/helpers/dateFormatter'

function setConsole(server) {
  if (server === 'production') {
    console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
  }
}

function activateGoogleAnalitycs(server, trackingCode) {
  if (server === 'production') {
    ReactGA.initialize(trackingCode)
    let ga = ReactGA.ga()
    ga('set', 'allowAdFeatures', false)
  }
}

function activateGoogleTagManager(server, gtmId) {
  if (server === 'production') {
    TagManager.initialize({
      gtmId
    })
  }
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

const fetchConfig = () =>
  new Promise((resolve, reject) => {
    xhrConfig = new XMLHttpRequest()
    xhrConfig.open('GET', '/config.json', true)
    xhrConfig.setRequestHeader('Cache-Control', 'no-cache')
    xhrConfig.onload = resolve
    xhrConfig.onerror = reject // () => reject(xhrConfig.statusText); //  console.error(xhrConfig.statusText);
    xhrConfig.send(null)
  })

function processResults(serverConfig, maintenance) {
  setDiffFromServerTime(maintenance && maintenance.startTime)
  setServerConfig(serverConfig)
  setReduxStore()
  setBaseUrls({
    baseUrl: serverConfig.baseUrlForApi,
    staticContentBaseUrl: serverConfig.staticContentBaseUrl,
    authorizationUrl: serverConfig.authorizationUrl
  })
  setConsole(serverConfig.server)
  activateGoogleAnalitycs(serverConfig.server, serverConfig.gaTrackingCode)
  activateGoogleTagManager(serverConfig.server, serverConfig.gtm_id)
  let um = setUserManagerAuthorizationUrl(
    serverConfig.authorizationUrl,
    serverConfig.clientId
  )
  if (maintenance && maintenance.maintenance) {
    render(
      <MaintenancePage maintenance={maintenance} />,
      document.getElementById('root')
    )
  } else {
    let url = serverConfig.authorizationUrl
    switch (window.location.pathname) {
      case '/promotion/signup':
        window.location =
          url + `/Signup?clientId=accounts${window.location.search}`
        break
      case '/promotion/reset-password':
        window.location =
          url + `/ResetPassword?clientId=accounts${window.location.search}`
        break
      case '/promotion/dashboard':
        window.location = url + `/Authorization/Login${window.location.search}`
        break
      default:
        render(
          <Provider store={store}>
            <OidcProvider store={store} userManager={um}>
              <App />
            </OidcProvider>
          </Provider>,
          document.getElementById('root')
        )
    }
  }
}

let xhrMaintenance
let xhrConfig

function determineMaintenance(responseText) {
  if (responseText) {
    let json = JSON.parse(responseText)
    return json
  }
  return { maintenance: false }
}

function requestOnload(e) {
  if (xhrConfig.readyState === 4 && xhrConfig.status === 200) {
    let serverConfig = JSON.parse(xhrConfig.responseText)
    xhrMaintenance = new XMLHttpRequest()
    xhrMaintenance.open(
      'GET',
      `${serverConfig.baseUrlForApi}/infrastructure/v1/maintenance`,
      true
    )
    xhrMaintenance.setRequestHeader('Cache-Control', 'no-cache')
    xhrMaintenance.onload = e => {
      if (
        xhrMaintenance.readyState === 4 &&
        (xhrMaintenance.status === 200 || xhrMaintenance.status === 204)
      )
        processResults(
          serverConfig,
          determineMaintenance(xhrMaintenance.responseText)
        )
      else processResults(serverConfig, false)
    }
    xhrMaintenance.onerror = e => processResults(serverConfig, false)
    xhrMaintenance.send(null)
  }
}

switch (window.location.pathname) {
  case '/silent_renew':
    processSilentRenew()
    break
  case '/signout':
    let authorizeUrl = decodeURIComponent(getQueryVariable('iss'))
    if (authorizeUrl) {
      let um = createUserManager(
        updateObject(userManagerConfig, { authority: authorizeUrl })
      )
      um.removeUser()
    }
    break
  case '/set_language':
    let lang = getQueryVariable('culture')
    setCookie('lang', lang, 300)
    break
  case '/callback':
    render(
      <CallbackComponent
        userManager={createUserManager({
          clockSkew: userManagerConfig.clockSkew
        })} // { clockSkew: userManagerConfig.clockSkew }
        successCallback={() => {
          // detecting trusted devices
          if (window.location.search.includes('trust_enabled=true')) {
            sessionStorage.setItem('TEMP_TRUST_ENABLED', true)
          }

          const url = sessionStorage.getItem('TEMP_REDIRECT_URL')
          if (url) {
            sessionStorage.removeItem('TEMP_REDIRECT_URL')
            window.location = url
          } else window.location = '/'
        }}
        errorCallback={e => {
          if (e.message === 'No matching state found in storage') {
            window.location = '/'
            return
          }
          const location =
            e.message === 'No state in response' ? '/' : '/general-error'
          setBaseUrls({ baseUrl: '/api' })
          logGeneralError(
            JSON.stringify({
              error: e.message.toString(),
              userAgent: window.navigator.userAgent,
              hashLength: window.location.hash ? window.location.hash.length : 0
            }),
            false
          )
            .then(() => (window.location = location))
            .catch(() => (window.location = location))
          return
        }}
      >
        <p />
      </CallbackComponent>,
      document.getElementById('root')
    )
    break
  default:
    fetchConfig()
      .then(requestOnload)
      .catch(e => console.error(xhrConfig.statusText))
    break
}
