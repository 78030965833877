export let baseUrl
export let staticContentBaseUrl
export let authorizationUrl

export let setBaseUrls = ({
  baseUrl: _baseUrl,
  staticContentBaseUrl: _staticContentBaseUrl,
  authorizationUrl: _authorizationUrl
}) => {
  baseUrl = _baseUrl
  staticContentBaseUrl = _staticContentBaseUrl
  authorizationUrl = _authorizationUrl
}
