import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ConfirmDialog from '../../../common/Modal/ConfirmDialog'
import LbModal from '../../../common/Modal/LbModal'
import i18n from '../../../../i18n'
import { MarketingConsentsContainer } from '../../AdditionalServices/MarketingConsents/MarketingConsentsContainer'
import { updateAvailableMarketingConsents } from '../../../api/configuration.api'
import userApi from '../../../api/user-setting.api'
import { LbToast } from '../../../common/SimpleComponents/LBToasts'
import { reselectWasAddsIntroduced } from '../../../redux/reselects/user-settings.reselect'
import hasUserPermission from '../../../helpers/hasUserPermission'

export const MarketingConsents = () => {
  const confirmModal = useRef()
  const consentsModalRef = useRef()
  const { wasAddsIntroduced, isConfigurationLoading } = useSelector(
    reselectWasAddsIntroduced
  )

  const handleAllMarketingConsents = useCallback(async () => {
    try {
      await updateAvailableMarketingConsents({
        approveAll: true
      })
      LbToast('success', i18n.t('MarketingConsents.SavedSuccessfully'))

      let setting = {
        keyword: 'wasAddsIntroduced',
        settingBody: true
        // settingBody: ''
      }
      await userApi.saveUserSettingsAjax(setting, true)
    } catch (error) {
      LbToast('error', error.errors[0] || i18n.t('Common.Error'))
    }
  }, [])

  const handleCheck = useCallback(() => {
    if (
      !isConfigurationLoading &&
      hasUserPermission(false, true) &&
      (wasAddsIntroduced === undefined || wasAddsIntroduced === false) &&
      confirmModal.current
    )
      confirmModal.current.handleOpenModal()
  }, [isConfigurationLoading, wasAddsIntroduced])

  useEffect(() => {
    handleCheck()
  }, [handleCheck])

  return (
    <>
      <ConfirmDialog
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        hideCloseTopButton={true}
        title={i18n.t('MarketingConsents.Modal.Title')}
        className="marketing-confirm"
        message={
          <div className="flex-start flex-col fnt-15 fnt-low">
            <p className='pb-24'>
              {i18n.t('MarketingConsents.Modal.Description')}
            </p>
            <p> {i18n.t('MarketingConsents.Modal.DescriptionTwo')}</p>
          </div>
        }
        ref={confirmModal}
        handleOk={handleAllMarketingConsents}
        handleCancel={() => {
          if (consentsModalRef.current)
            consentsModalRef.current.handleOpenModal()
        }}
      />

      <LbModal
        ref={consentsModalRef}
        className="mw-600 lb-marketing-modal"
        header={i18n.t('AdditionalServices.MarketingConsents.Modal.Title')}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        hideCloseTopButton={true}
      >
        <MarketingConsentsContainer
          onClose={() => consentsModalRef.current.handleCloseModal()}
        />
      </LbModal>
    </>
  )
}
