import React, { Component, Fragment } from 'react'
import LbModal from './LbModal'
import i18n from '../../../i18n'

export default class ConfirmDialog extends Component {
  handleOpenModal = () => {
    this.modal.handleOpenModal()
  }

  handleOk = () => {
    if (this.props.handleOk) {
      this.props.handleOk()
    }
    this.modal.handleCloseModal()
  }

  handleCancel = () => {
    if (this.props.handleCancel) {
      this.props.handleCancel()
    }

    this.modal.handleCloseModal()
  }
  undefinedToEmptyString(className) {
    return className ? className : ''
  }
  render() {
    return (
      <Fragment>
        <LbModal
          ref={modal => {
            this.modal = modal
          }}
          header={this.props.title}
          className={`${this.undefinedToEmptyString(this.props.className)}`}
          // style={{ content: { width: '500px', height: 'auto' } }}
          shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
          hideCloseTopButton={this.props.hideCloseTopButton}
          modalCloseCallback={this.props.modalCloseCallback}
          shouldCloseOnEsc={this.props.shouldCloseOnEsc}
        >
          <div className="lb-confirm-text">{this.props.message}</div>
          <div className="lb-popup-footer small-btn-group">
            {!this.props.hideCancel && (
              <input
                id={`input-noButton-${this.props.id}`}
                type="button"
                className="lb-btn btn-border btn-white btn-confirm"
                value={this.props.refuseMessage || i18n.t('Common.No')}
                onClick={this.handleCancel}
              />
            )}
            <input
              id={`input-yesButton-${this.props.id}`}
              type="button"
              className="lb-btn btn-red btn-confirm"
              value={this.props.acceptMessage || i18n.t('Common.Yes')}
              onClick={this.handleOk}
              style={{width: 'fit-content'}}
            />
          </div>
        </LbModal>
      </Fragment>
    )
  }
}
